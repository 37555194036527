// Auth Dispatch States
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

// Profile Dispatch States
export const LOAD_TREE_SUCCESS = 'LOAD_TREE_SUCCESS';
export const LOAD_TREE_FAIL = 'LOAD_TREE_FAIL';
export const UPDATE_TREE_SUCCESS = 'UPDATE_TREE_SUCCESS';
export const UPDATE_TREE_FAIL = 'UPDATE_TREE_FAIL';
import React from 'react'
import { FaBehance, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
export const mainLinks = [
  {
    id: 1,
    url: '/Tree',
    text: 'Tree',
  },
  {
    id: 2,
    url: '/List',
    text: 'List',
  },
  {
    id: 3,
    url: '/about',
    text: 'about',
  },
]

export const guestLinks = [
  {
    id: 1,
    url: '/login',
    text: 'login',
  },
//  {
//    id: 2,
//    url: '/register',
//    text: 'Register',
//  },
  {
    id: 3,
    url: '/about',
    text: 'about',
  },
]

export const social = [
  {
    id: 1,
    url: 'https://www.twitter.com',
    icon: <FaFacebook />,
  },
  {
    id: 2,
    url: 'https://www.twitter.com',
    icon: <FaTwitter />,
  },
  {
    id: 3,
    url: 'https://www.twitter.com',
    icon: <FaLinkedin />,
  },
  {
    id: 4,
    url: 'https://www.twitter.com',
    icon: <FaBehance />,
  },
]
